import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BrowserFrame from '../components/BrowserFrame';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import RestLg from '../components/icons/RestLg';

const AdobeCommerceSubscriptionsPage = () => (
  <Layout>
    <Seo title="Adobe Commerce Subscriptions and Recurring Orders" />

    <Header graphic={undefined}>Adobe Commerce Subscriptions</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="">
            <h2 className="text-5xl font-normal">Subscribe Pro for Adobe Commerce / Magento</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              Subscribe Pro offers the most fully featured and customizable integration for Magento 2 and Adobe Commerce of any
              subscription solution on the market.
            </p>
            <p className="mt-4 text-lg text-sp-black font-light">
              Our solution includes features like full integration with Magento cart and catalog discount rules, support for
              Magento customer groups, Magento admin panel ordering including subscriptions and mixed carts and other advanced
              features.
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center items-start">
              <BrowserFrame className="max-w-[900px]">
                <StaticImage
                  className="object-contain "
                  src="../images/gol_vitamin_code_bone_grow_system_1_pdp.png"
                  width={900}
                  quality={95}
                  objectFit="contain"
                  alt="Product Details Page Subscription Widget"
                />
              </BrowserFrame>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="col-start-2">
            <h2 className="text-5xl font-normal">Powerful and Flexible APIs</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              We offer APIs covering all features available in the Subscribe Pro platform. The Subscribe Pro API is a standard
              REST-style API that accepts and returns JSON. We are committed to making sure all data stored by our subscription
              commerce platform is available via API and that all operations may be completed via API as well.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              We have architected our platform to provide a solid foundation for the long term. Our support team is never more
              than a phone call away. And when you need our help, we strive to provide truly world class support.
            </p>
          </div>
          <div className="col-start-1 flex flex-col items-center">
            <RestLg className="w-[18rem] h-auto" />
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="">
            <h2 className="text-6xl font-normal">Powerful Merchant App</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              The Subscribe Pro Merchant App is powerful customer service tool. Your subscribers are your most loyal customers and
              we believe it is essential your customer service team has access to the best tools to manage those customers data.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              We equip your customer service team with a toolkit for managing subscription queries, updating customer information,
              and solving problems—all within a single easy-to-use and flexible app.
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center items-start">
              <BrowserFrame className="max-w-[900px]">
                <StaticImage
                  className="object-contain "
                  src="../images/sp_merchant_app_customers_demo.png"
                  width={900}
                  quality={95}
                  objectFit="contain"
                  alt="Subscribe Pro Merchant App | Manager your loyal subscription customers and their data"
                />
              </BrowserFrame>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="col-start-2">
            <h2 className="text-5xl font-normal">Highly Integrated With Magento</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              Subscribe Pro is highly integrated with Magento. Subscribe Pro can place recurring orders directly in your Magento
              store, giving you the full benefit of Magento native discounting, tax and shipping calculation and seamlessly
              integrating via your existing order management and ERP channels.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              We have architected our platform to provide a solid foundation for the long term. Our support team is never more
              than a phone call away. And when you need our help, we strive to provide truly world class support.
            </p>
          </div>
          <div className="col-start-1 flex flex-col items-center">
            <BrowserFrame className="max-w-[900px]">
              <StaticImage
                className="object-contain "
                src="../images/m2_admin_cart_price_rules.png"
                width={900}
                quality={95}
                objectFit="contain"
                alt="Magento 2 Admin Panel Cart Discount Rules With Subscribe Pro"
              />
            </BrowserFrame>{' '}
          </div>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default AdobeCommerceSubscriptionsPage;
